import * as Honeybadger from '@honeybadger-io/js'

if (process.env.HONEYBADGER_API_KEY) {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

  if (isIE11 == false) {
    Honeybadger.configure({
      apiKey: process.env.HONEYBADGER_API_KEY,
      environment: process.env.HEROKU_APP_NAME,
      revision: process.env.HEROKU_SLUG_COMMIT
    })

    const meta = document.querySelector('meta[name="honeybadger-data"]')
    if (meta) {
      Honeybadger.setContext(JSON.parse(atob(meta.content)))
    }

    window.Honeybadger = Honeybadger
  }
}
